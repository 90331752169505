export const areas = [
    'Drenthe',
    'Flevoland',
    'Friesland',
    'Gelderland',
    'Groningen',
    'Limburg',
    'Noord-Brabant',
    'Noord-Holland',
    'Overijssel',
    'Utrecht',
    'Zeeland',
    'Zuid-Holland',
]

export const employmentTypes = [
    {
        key: 'Fulltime',
        value: 'FULLTIME'
    },
    {
        key: 'Parttime',
        value: 'PARTTIME'
    },
    {
        key: 'ZZP',
        value: 'CONTRACTOR'
    },
    {
        key: 'Stage',
        value: 'INTERN'
    },
]

export const experienceTypes = [
    {
        key: 'Minder dan 3 jaar',
        value: 'under_3_years_experience'
    },
    {
        key: 'Meer dan 3 jaar',
        value: 'more_than_3_years_experience'
    },
    {
        key: 'Géén ervaring',
        value: 'no_experience'
    },
    {
        key: 'Géén diploma',
        value: 'no_degree'
    },
]