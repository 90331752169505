import { useState, createContext, useContext, useEffect } from 'react';

const apiData = createContext();

export function useApiData() {
    return useContext(apiData);
}

export function ApiDataProvider({ children }) {

    const [data, setData] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        data && setLoading(false);
    }, [data]);
    
    const value = {
        data,
        setData,
        loading,
        setLoading,
    }

    return (
        <apiData.Provider value={value}>
            {children}
        </apiData.Provider>
    )
}