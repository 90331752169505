import './JobOpeningCard.css';

// functions
import { getLabelByKey } from '../api/functions';

// data
import { employmentTypes, experienceTypes } from '../api/Data';

const JobOpeningCard = ({ job }) => {

    const dateText = job.job_posted_at_datetime_utc;
    const date = new Date(dateText);

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const nlDate = date.toLocaleDateString('nl-NL', options);

    const salaryIndication = job.job_salary_currency && job.job_min_salary && job.job_max_salary && job.job_salary_period ?
    `${job.job_salary_currency} ${job.job_min_salary} - ${job.job_salary_currency} ${job.job_max_salary} / ${job.job_salary_period}` :
    `Geen salaris indicatie beschikbaar`

    return (
        <div className="job-opening-card">
            <div className='header'>
                { job.employer_logo && <img src={job.employer_logo} className='logo' /> }
                <h3>{job.employer_name}</h3>
                <div className='details'>
                    { job.job_city && <p>{job.job_city}</p> }
                    { job.job_employment_type && <p>{getLabelByKey(job.job_employment_type, employmentTypes)}</p> }
                    <p>{nlDate}</p>
                </div>
            </div>
            <div className='content'>
                <h2 className='title'>{job.job_title}</h2>
                <p>{salaryIndication}</p>
            </div>
            {/* <iframe 
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.0206305264633!2d${job.job_longitude}!3d${job.job_latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zM2DCsDM2JzU1LjAiTiA0wrAxNScwMi43Ilc!5e0!3m2!1sen!2sus!4v1687947374681!5m2!1sen!2sus`} 
                width="800" 
                height="300" 
                style={{border: '0'}} 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
            </iframe> */}
            <div className='publisher'>
                <p>Gepubliceerd door:</p>
                <h3>{job.job_publisher}</h3>
            </div>
            <div className='links'>
                <a href={job.job_apply_link} target='blank'><i className="fa-solid fa-arrow-up-right-from-square"></i> Solliciteren</a>
                <a href={job.job_google_link} target='blank'><i className="fa-solid fa-arrow-up-right-from-square"></i> Vind vacature op Google</a>
            </div>
        </div>
    );
}

export default JobOpeningCard;