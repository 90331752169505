import './JobFinderView.css';

import { useState } from "react";

// context
import { useApiData } from '../context/DataContext';

// functions
import { getData } from "../api/Api";

// data
import { areas, employmentTypes, experienceTypes } from "../api/Data";


const JobFinderView = () => {

    const { setData, setLoading } = useApiData();

    const [jobDescription, setJobDescription] = useState('');
    const [area, setArea] = useState('');
    const [employmentType, setEmploymentType] = useState('');
    const [experienceType, setExperienceType] = useState('');

    const query = `${jobDescription} ${area && 'in'} ${area}, Netherlands`;

    const parameters = { 
        page: '1',
        num_pages: '10',
        date_posted: 'all',
        remote_jobs_only: false,
        employment_types: employmentType,
        job_requirements: experienceType,
        job_titles: null,
        company_types: null,
        employer: null,
        radius: null,
        categories: null,
    }
    
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setData(null);
        getData(query, parameters, setData);
    }

    return (
        <div className='job-finder-view view'>
            <h1 className='view-title'>Zoek naar vacatures</h1>
            <form onSubmit={(e) => onSubmit(e)}>
                <div className='input'>
                    <label htmlFor='job-description'>Vacature omschrijving</label>
                    <input type='text' name='job-description' value={jobDescription} onChange={(e) => {setJobDescription(e.target.value)}} />
                </div>
                <div className='input'>
                    <label htmlFor='area'>Provincie</label>
                    <select name='area' value={area} onChange={(e) => setArea(e.target.value)}>
                        <option value={''}>Alle Provincies</option>
                        {areas.map(area => <option value={area}>{area}</option>)}
                    </select>
                </div>
                {/* <div className='input'>
                    <label htmlFor='employment_type'>Aanstelling</label>
                    <select name='employment_type' value={employmentType} onChange={(e) => {setEmploymentType(e.target.value)}}>
                        <option value={''}>Alles</option>
                        {employmentTypes.map(type => <option value={type.value}>{type.key}</option>)}
                    </select>
                </div>
                <div className='input'>
                    <label htmlFor='experience'>Ervaring</label>
                    <select name='experience' value={experienceType} onChange={(e) => {setExperienceType(e.target.value)}}>
                        <option value={''}>Alles</option>
                        {experienceTypes.map(type => <option value={type.value}>{type.key}</option>)}
                    </select>
                </div> */}
                <button type='submit' value={'Vind vacatures'}>Vind vacatures</button>
            </form>
        </div>
    );
}

export default JobFinderView;