// assets
import loadingSpinner from '../assets/loading-spinner.gif';

// components
import JobOpeningCard from "../components/JobOpeningCard";

// context
import { useApiData } from "../context/DataContext";

const JobOpeningsView = () => {
    
    const { data, loading } = useApiData();

    return (
        <div className="job-openings-view view">
            { loading && <img src={loadingSpinner} className='loading-spinner' /> }
            {
                !loading && data && data.data.sort((a, b) => {
                const timestampA = a.job_posted_at_timestamp;
                const timestampB = b.job_posted_at_timestamp;
                return timestampB - timestampA;
                })
                .map(opening => <JobOpeningCard job={opening} />)
            }
            { !loading && data && data.data.length === 0 && <h3>Geen vacatures gevonden</h3>}
        </div>
    )
}

export default JobOpeningsView;