// views
import JobFinderView from "./views/JobFinderView";
import JobOpeningsView from "./views/JobOpeningsView";

const App = () => {

  return (
    <div className="App">
      <JobFinderView />
      <JobOpeningsView />
    </div>
  );
}

export default App;
